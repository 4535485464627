import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PescatoreGuard } from './services/pescatore.service';
import { UtenteNonLoggatoGuard } from './services/utente-non-loggato.service';
import { PescatoreLoggatoGuard } from './services/pescatore-loggato.service';
import { GuardiaLoggataGuard } from './services/guardia-loggata.service';
import { UtenteLoggatoGuard } from './services/utente-loggato.service';

const routes: Routes = [
  {
    path: 'home',
    canActivate: [PescatoreGuard],
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'filtri',
    canActivate: [PescatoreGuard],
    loadChildren: () => import('./pages/filtri/filtri.module').then( m => m.FiltriPageModule)
  },
  {
    path: 'permessi-acquistabili',
    canActivate: [PescatoreGuard],
    loadChildren: () => import('./pages/permessi-acquistabili/permessi-acquistabili.module').then( m => m.PermessiAcquistabiliPageModule)
  },
  {
    path: 'acquista-permesso',
    canActivate: [PescatoreGuard],
    loadChildren: () => import('./pages/acquista-permesso/acquista-permesso.module').then( m => m.AcquistaPermessoPageModule)
  },
  {
    path: 'login',
    canActivate: [UtenteNonLoggatoGuard],
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'activation-modal',
    canActivate: [UtenteNonLoggatoGuard],
    loadChildren: () => import('./pages/activation-modal/activation-modal.module').then( m => m.ActivationModalPageModule)
  },
  {
    path: 'dati-dimenticati-modal',
    canActivate: [UtenteNonLoggatoGuard],
    loadChildren: () => import('./pages/dati-dimenticati-modal/dati-dimenticati-modal.module').then( m => m.DatiDimenticatiModalPageModule)
  },
  {
    path: 'condizioni-modal',
    canActivate: [PescatoreLoggatoGuard],
    loadChildren: () => import('./pages/condizioni-modal/condizioni-modal.module').then( m => m.CondizioniModalPageModule)
  },
  {
    path: 'stripe',
    canActivate: [PescatoreLoggatoGuard],
    loadChildren: () => import('./pages/stripe/stripe.module').then( m => m.StripePageModule)
  },
  {
    path: 'prenotazione-modal',
    canActivate: [PescatoreLoggatoGuard],
    loadChildren: () => import('./pages/prenotazione-modal/prenotazione-modal.module').then( m => m.PrenotazioneModalPageModule)
  },
  {
    path: 'miei-permessi',
    canActivate: [PescatoreLoggatoGuard],
    loadChildren: () => import('./pages/miei-permessi/miei-permessi.module').then( m => m.MieiPermessiPageModule)
  },
  {
    path: 'contatti-modal',
    canActivate: [PescatoreGuard],
    loadChildren: () => import('./pages/contatti-modal/contatti-modal.module').then( m => m.ContattiModalPageModule)
  },
  {
    path: 'mie-annotazioni-modal',
    canActivate: [PescatoreLoggatoGuard],
    loadChildren: () => import('./pages/mie-annotazioni-modal/mie-annotazioni-modal.module').then( m => m.MieAnnotazioniModalPageModule)
  },
  {
    path: 'segnacatture',
    canActivate: [PescatoreLoggatoGuard],
    loadChildren: () => import('./pages/segnacatture/segnacatture.module').then( m => m.SegnacatturePageModule)
  },
  {
    path: 'catture-modal',
    canActivate: [PescatoreLoggatoGuard],
    loadChildren: () => import('./pages/catture-modal/catture-modal.module').then( m => m.CattureModalPageModule)
  },
  {
    path: 'start',
    loadChildren: () => import('./pages/start/start.module').then( m => m.StartPageModule)
  },
  {
    path: 'home-guardiapesca',
    canActivate: [GuardiaLoggataGuard],
    loadChildren: () => import('./pages/home-guardiapesca/home-guardiapesca.module').then( m => m.HomeGuardiapescaPageModule)
  },
  {
    path: 'movimenti-pescatori',
    canActivate: [GuardiaLoggataGuard],
    loadChildren: () => import('./pages/movimenti-pescatori/movimenti-pescatori.module').then( m => m.MovimentiPescatoriPageModule)
  },
  {
    path: 'dettaglio-pescatore',
    canActivate: [GuardiaLoggataGuard],
    loadChildren: () => import('./pages/dettaglio-pescatore/dettaglio-pescatore.module').then( m => m.DettaglioPescatorePageModule)
  },
  {
    path: 'annotazione-modal',
    canActivate: [GuardiaLoggataGuard],
    loadChildren: () => import('./pages/annotazione-modal/annotazione-modal.module').then( m => m.AnnotazioneModalPageModule)
  },
  {
    path: 'pescatori-per-permesso',
    canActivate: [GuardiaLoggataGuard],
    loadChildren: () => import('./pages/pescatori-per-permesso/pescatori-per-permesso.module').then( m => m.PescatoriPerPermessoPageModule)
  },
  {
    path: 'modifica-dati',
    canActivate: [UtenteLoggatoGuard],
    loadChildren: () => import('./pages/modifica-dati/modifica-dati.module').then( m => m.ModificaDatiPageModule)
  },
  {
    path: 'mappa',
    loadChildren: () => import('./pages/mappa/mappa.module').then( m => m.MappaPageModule)
  },
  {
    path: 'archivio-permessi',
    canActivate: [PescatoreLoggatoGuard],
    loadChildren: () => import('./pages/archivio-permessi/archivio-permessi.module').then( m => m.ArchivioPermessiPageModule)
  },
  {
    path: 'guide',
    canActivate: [PescatoreGuard],
    loadChildren: () => import('./pages/guide/guide.module').then( m => m.GuidePageModule)
  },
  {
    path: 'guide/:idAssociazione',
    canActivate: [PescatoreGuard],
    loadChildren: () => import('./pages/guide/guide.module').then( m => m.GuidePageModule)
  },
  {
    path: 'dettaglio-uscite',
    canActivate: [PescatoreGuard],
    loadChildren: () => import('./pages/dettaglio-uscite/dettaglio-uscite.module').then( m => m.DettaglioUscitePageModule)
  },
  {
    path: 'riepilogo-uscita',
    canActivate: [PescatoreGuard],
    loadChildren: () => import('./pages/riepilogo-uscita/riepilogo-uscita.module').then( m => m.RiepilogoUscitaPageModule)
  },
  {
    path: '',
    redirectTo: '/start',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/start',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
