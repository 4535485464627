import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { UserDataService } from './user-data.service';

@Injectable({
  providedIn: 'root'
})
export class PescatoreLoggatoService {

  constructor(
    private router: Router,
    private userDataService: UserDataService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.userDataService.tipoUtente == "utente" && !!this.userDataService.token;
  }
}

export const PescatoreLoggatoGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(PescatoreLoggatoService).canActivate(next, state);
}