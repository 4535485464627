import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BackendService } from './services/backend.service';
import { UserDataService } from './services/user-data.service';
import { Preferences } from '@capacitor/preferences';
import { NavigationCancel, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { UtilsService } from './services/utils.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(
    private loadingController: LoadingController,
    private alertController: AlertController,
    private navController: NavController,
    private translate: TranslateService,
    public userDataService: UserDataService,
    private backendService: BackendService,
    private utils: UtilsService,
    private router: Router
  ) {
    
  }

  async onLogoutClick() {
    
    const loading = await this.loadingController.create({
			message: this.translate.instant("SOLO_UN_ATTIMO")
		});
	
		await loading.present();

    this.backendService.logout({
      token: this.userDataService.token
    }).subscribe({
      next: async (data) => {
        await loading.dismiss();

        switch (data.status) {
          case "OK":

            this.userDataService.clear();

            await Preferences.clear();

            this.navController.navigateRoot("home", {
              queryParamsHandling: "merge",
              replaceUrl: true
            });

            break;
          
          case "KO":

            const alert = await this.alertController.create({
              header: this.translate.instant("ERRORE"),
              message: data.message,
              backdropDismiss: false,
              buttons: [this.translate.instant("OK")]
            });
        
            await alert.present();

            break;
        }
      },
      error: async () => {
        await loading.dismiss();

        const alert = await this.alertController.create({
          header: this.translate.instant("ERRORE"),
          message: this.translate.instant("ERRORE_INVIO_DATI"),
          backdropDismiss: false,
          buttons: [this.translate.instant("OK")]
        });
    
        await alert.present();
      }
    });
  }

  ngOnInit() {

    this.translate.setDefaultLang("en");

    const bl = this.translate.getBrowserLang();
    let bSupported = false;

    for (const l of this.utils.SUPPORTED_LANGUAGES) {
      if (l.code == bl) {
        bSupported = true;
        break;
      }
    }

    if (bSupported) {
      this.translate.use(bl);
    }
    else {
      this.translate.use("en");
    }

    const subscription = this.router.events.subscribe(
      (event) => {
        if (event instanceof NavigationEnd) {

          subscription.unsubscribe();

          let usp = new URLSearchParams();

          if (event.url.indexOf("?") >= 0) {
            usp = new URLSearchParams(event.url.substring(event.url.indexOf("?") + 1));
          }
          
          const widget = usp.get("widget");
          const associazione = usp.get("associazione");
          const orizzontale = usp.get("orizzontale");
          const data = usp.get("data");
          const tipologie = usp.get("tipologie");
    
          const qp: any = {};
    
          if (widget && widget == "1") {
            qp.widget = "1";
    
            if (associazione) {
              qp.associazione = associazione;
            }

            if (orizzontale && orizzontale == "1") {
              qp.orizzontale = "1";
            }
          }
          else if (data && typeof(tipologie) == "string") {
            qp.data = data;
            qp.tipologie = tipologie;
    
            if (associazione) {
              qp.associazione = associazione;
            }
          }
          
          // serve per forzare la homepage quando si refresha
          this.navController.navigateRoot("start", {
            queryParamsHandling: "merge",
            replaceUrl: true,
            queryParams: qp
          });
        }
        else if (event instanceof NavigationCancel) {
          // nel caso al refresh le guardie blocchino la navigazione ritorno sullo start
          this.navController.navigateRoot("start", {
            queryParamsHandling: "merge",
            replaceUrl: true
          });
        }
      }
    );
  }
}
