import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BackendService {

	private readonly API_KEY = "abcd-efgh-ijkl-mnop";

  private headers: HttpHeaders;
	private options: {
		headers: HttpHeaders,
		params?: HttpParams
	};

  constructor(
    private http: HttpClient
  ) {
    this.headers = new HttpHeaders({ "Content-Type": "application/json", "api-key": this.API_KEY });
	this.options = { headers: this.headers };
  }

  	registrazione(obj: {
		email: string;
		password: string;
		nome: string;
		cognome: string;
		dataNascita: string;
		lingua: string;
		comunicazioni: boolean;
		nazionalita?: string;
		telefono?: string;
	}): Observable<any> {

		delete this.options.params;

		const o = {
			email: obj.email,
			password: obj.password,
			nome: obj.nome,
			cognome: obj.cognome,
			dataNascita: obj.dataNascita,
			lingua: obj.lingua,
			comunicazioni: obj.comunicazioni,
			nazionalita: obj.nazionalita,
			telefono: obj.telefono
		};

		return this.http.post(environment.basePath + "/api/registrazione", o, this.options);
	  }

	  login(obj: {
		email: string;
		password: string;
		tipoUtente: string;
	}): Observable<any> {

		delete this.options.params;

		const o = {
			email: obj.email,
			password: obj.password,
			tipoUtente: obj.tipoUtente
		};

		return this.http.post(environment.basePath + "/api/login", o, this.options);
	  }

	  checkToken(obj: {
		token: string;
	}): Observable<any> {

		delete this.options.params;

		const o = {
			token: obj.token
		};

		return this.http.post(environment.basePath + "/api/check-token", o, this.options);
	  }

	  logout(obj: {
		token: string;
	}): Observable<any> {

		delete this.options.params;

		const o = {
			token: obj.token
		};

		return this.http.post(environment.basePath + "/api/logout", o, this.options);
	  }
	  
	  checkEmail(obj: {
		email: string;
		codiceAttivazione: string;
	}): Observable<any> {

		delete this.options.params;

		const o = {
			email: obj.email,
			codiceAttivazione: obj.codiceAttivazione
		};

		return this.http.put(environment.basePath + "/api/check-email", o, this.options);
	}

	richiediNuovaPassword(obj: {
		email: string;
		tipoUtente: string;
	}): Observable<any> {

		delete this.options.params;

		let params = new HttpParams();
		params = params.set("email", obj.email);
		params = params.set("tipoUtente", obj.tipoUtente);

		this.options.params = params;

		return this.http.get(environment.basePath + "/api/nuova-password", this.options);
	}

	getDatiUtente(obj: {
		token: string;
	}): Observable<any> {

		delete this.options.params;

		let params = new HttpParams();
		params = params.set("token", obj.token);

		this.options.params = params;

		return this.http.get(environment.basePath + "/api/dati-utente", this.options);
	}

	modificaDatiUtente(obj: {
		token: string;
		email: string;
		nome: string;
		cognome: string;
		dataNascita: string;
		lingua: string;
		comunicazioni: boolean;
		nazionalita?: string;
		telefono?: string;
	}): Observable<any> {

		delete this.options.params;

		const o = {
			token: obj.token,
			email: obj.email,
			nome: obj.nome,
			cognome: obj.cognome,
			dataNascita: obj.dataNascita,
			lingua: obj.lingua,
			comunicazioni: obj.comunicazioni,
			nazionalita: obj.nazionalita,
			telefono: obj.telefono
		};

		return this.http.put(environment.basePath + "/api/dati-utente", o, this.options);
	}

	modificaPasswordUtente(obj: {
		token: string;
		oldPassword: string;
		newPassword: string;
	}): Observable<any> {

		delete this.options.params;

		const o = {
			token: obj.token,
			oldPassword: obj.oldPassword,
			newPassword: obj.newPassword
		};

		return this.http.put(environment.basePath + "/api/password-utente", o, this.options);
	}

 	getElencoAssociazioni(): Observable<any> {
		delete this.options.params;

		return this.http.get(environment.basePath + "/api/associazioni", this.options);
	}

	getElencoTipologiePermessi(obj: {
		lingua: string;
	}): Observable<any> {
		delete this.options.params;

		let params = new HttpParams();
		params = params.set("lingua", obj.lingua);

		this.options.params = params;

		return this.http.get(environment.basePath + "/api/tipologie-permessi", this.options);
	}

	getPermessiAcquistabili(obj: {
		token?: string;
		data: string;
		associazioni: number[],
		tipologie: number[],
		emailAltroUtente?: string;
	}): Observable<any> {

		delete this.options.params;

		const sAssociazioni = obj.associazioni ? obj.associazioni.join(",") : "";
		const sTipologie = obj.tipologie ? obj.tipologie.join(",") : "";
		
		let params = new HttpParams();
		params = params.set("data", obj.data);
		params = params.set("associazioni", sAssociazioni);
		params = params.set("tipologie", sTipologie);

		if (obj.token) {
			params = params.set("token", obj.token);
		}

		if (obj.emailAltroUtente) {
			params = params.set("emailAltroUtente", obj.emailAltroUtente);
		}

		this.options.params = params;

		return this.http.get(environment.basePath + "/api/permessi-acquistabili", this.options);
	}

	checkPermessoAcquistabile(obj: {
		token: string;
		idPermesso: number;
		data: string;
		idZona?: number;
		orario?: string;
		emailAltroUtente?: string;
	}): Observable<any> {

		delete this.options.params;

		let params = new HttpParams();
		params = params.set("token", obj.token);
		params = params.set("idPermesso", obj.idPermesso);
		params = params.set("data", obj.data);

		if (obj.idZona) {
			params = params.set("idZona", obj.idZona);
		}

		if (obj.orario) {
			params = params.set("orario", obj.orario);
		}

		if (obj.emailAltroUtente) {
			params = params.set("emailAltroUtente", obj.emailAltroUtente);
		}

		this.options.params = params;

		return this.http.get(environment.basePath + "/api/check-permesso-acquistabile", this.options);
	}

	checkEmailAltroUtente(obj: {
		token: string;
		email: string;
	}): Observable<any> {

		delete this.options.params;

		let params = new HttpParams();
		params = params.set("token", obj.token);
		params = params.set("email", obj.email);

		this.options.params = params;

		return this.http.get(environment.basePath + "/api/check-email-altro-utente", this.options);
	}

	getStripePaymentIntent(obj: {
		token: string;
		idPermesso: number;
		dataInizio: string;
		dataFine: string;
		idZona?: number;
		orario?: string;
		idTecnica?: number;
		emailAltroUtente?: string;
		minoreAccompagnato?: string;
		idCodiceSconto?: number;
		arrOpzioniAggiuntive?: number[];
	}): Observable<any> {

		delete this.options.params;

		let params = new HttpParams();
		params = params.set("token", obj.token);
		params = params.set("idPermesso", obj.idPermesso);
		params = params.set("dataInizio", obj.dataInizio);
		params = params.set("dataFine", obj.dataFine);

		if (obj.idZona) {
			params = params.set("idZona", obj.idZona);
		}

		if (obj.orario) {
			params = params.set("orario", obj.orario);
		}

		if (obj.idTecnica) {
			params = params.set("idTecnica", obj.idTecnica);
		}

		if (obj.emailAltroUtente) {
			params = params.set("emailAltroUtente", obj.emailAltroUtente);
		}

		if (obj.minoreAccompagnato) {
			params = params.set("minoreAccompagnato", obj.minoreAccompagnato);
		}

		if (obj.idCodiceSconto) {
			params = params.set("idCodiceSconto", obj.idCodiceSconto);
		}

		if (obj.arrOpzioniAggiuntive) {
			params = params.set("arrOpzioniAggiuntive", obj.arrOpzioniAggiuntive.join(","));
		}

		this.options.params = params;

		return this.http.get(environment.basePath + "/api/stripe-payment-intent", this.options);
	}

	acquistaPermesso(obj: {
		token: string;
		idPermesso: number;
		dataInizio: string;
		dataFine: string;
		idZona?: number;
		orario?: string;
		idTecnica?: number;
		emailAltroUtente?: string;
		minoreAccompagnato?: string;
		idCodiceSconto?: number;
		bConPrelievo?: boolean;
	}): Observable<any> {

		delete this.options.params;

		const o = {
			token: obj.token,
			idPermesso: obj.idPermesso,
			dataInizio: obj.dataInizio,
			dataFine: obj.dataFine,
			idZona: obj.idZona,
			orario: obj.orario,
			idTecnica: obj.idTecnica,
			emailAltroUtente: obj.emailAltroUtente,
			minoreAccompagnato: obj.minoreAccompagnato,
			idCodiceSconto: obj.idCodiceSconto,
			bConPrelievo: obj.bConPrelievo
		};

		return this.http.post(environment.basePath + "/api/acquista-permesso", o, this.options);
	}

	getZoneEPostiRimanenti(obj: {
		idPermesso: number;
		data: string;
	}): Observable<any> {

		delete this.options.params;

		let params = new HttpParams();
		params = params.set("idPermesso", obj.idPermesso);
		params = params.set("data", obj.data);

		this.options.params = params;

		return this.http.get(environment.basePath + "/api/zone-e-posti-rimanenti", this.options);
	}

	getPermessiUtente(obj: {
		token: string;
	}): Observable<any> {

		delete this.options.params;

		let params = new HttpParams();
		params = params.set("token", obj.token);

		this.options.params = params;

		return this.http.get(environment.basePath + "/api/permessi-utente", this.options);
	}

	cambiaDataPermesso(obj: {
		token: string;
		idAcquisto: number,
		data: string
	}): Observable<any> {

		delete this.options.params;

		const o = {
			token: obj.token,
			idAcquisto: obj.idAcquisto,
			data: obj.data
		};

		return this.http.put(environment.basePath + "/api/data-permesso", o, this.options);
	}

	cambiaTitolarePermesso(obj: {
		token: string;
		idAcquisto: number,
		email: string
	}): Observable<any> {

		delete this.options.params;

		const o = {
			token: obj.token,
			idAcquisto: obj.idAcquisto,
			email: obj.email
		};

		return this.http.put(environment.basePath + "/api/titolare-permesso", o, this.options);
	}

	getAnniArchivioPermessiUtente(obj: {
		token: string;
	}): Observable<any> {

		delete this.options.params;

		let params = new HttpParams();
		params = params.set("token", obj.token);

		this.options.params = params;

		return this.http.get(environment.basePath + "/api/anni-archivio-permessi-utente", this.options);
	}

	getArchivioPermessiUtente(obj: {
		token: string;
		anno: number;
	}): Observable<any> {

		delete this.options.params;

		let params = new HttpParams();
		params = params.set("token", obj.token);
		params = params.set("anno", obj.anno);

		this.options.params = params;

		return this.http.get(environment.basePath + "/api/archivio-permessi-utente", this.options);
	}

	getGuide(obj: {
		idAssociazione?: number;
	}): Observable<any> {

		delete this.options.params;

		let params = new HttpParams();

		if (obj.idAssociazione) {
			params = params.set("idAssociazione", obj.idAssociazione);
		}

		this.options.params = params;

		return this.http.get(environment.basePath + "/api/guide", this.options);
	}

	prenotaZona(obj: {
		token: string;
		idAcquistoPermesso: number;
		idZona: number;
		giorno: string;
		orario: string;
		idTecnica: number;
	}): Observable<any> {

		delete this.options.params;

		const o = {
			token: obj.token,
			idAcquistoPermesso: obj.idAcquistoPermesso,
			idZona: obj.idZona,
			giorno: obj.giorno,
			orario: obj.orario,
			idTecnica: obj.idTecnica
		};

		return this.http.post(environment.basePath + "/api/prenota-zona", o, this.options);
	}

	updateAllData(obj: {
		token: string;
		arrDati: any[];
	}): Observable<any> {

		delete this.options.params;

		let arr_permessi_modificati = [];

		for (const permesso of obj.arrDati) {
			if (permesso.modificato) {
				arr_permessi_modificati.push(permesso);
			}
		}

		if (arr_permessi_modificati.length == 0) {
			arr_permessi_modificati = obj.arrDati;
		}

		const o = {
			token: obj.token,
			dati: arr_permessi_modificati
		};

		return this.http.put(environment.basePath + "/api/update-all-data", o, this.options);
	}

	getPermessiGuardiapesca(obj: {
		token: string;
	}): Observable<any> {

		delete this.options.params;

		let params = new HttpParams();
		params = params.set("token", obj.token);

		this.options.params = params;

		return this.http.get(environment.basePath + "/api/permessi-guardiapesca", this.options);
	}

	getMovimentiDiOggi(obj: {
		token: string;
	}): Observable<any> {

		delete this.options.params;

		let params = new HttpParams();
		params = params.set("token", obj.token);

		this.options.params = params;

		return this.http.get(environment.basePath + "/api/movimenti-pescatori", this.options);
	}

	getBattutePescaEAnnotazioniInAcquistoPermesso(obj: {
		token: string;
		idAcquistoPermesso: number;
		data?: string;
		hooking?: boolean
	}): Observable<any> {

		delete this.options.params;

		let params = new HttpParams();
		params = params.set("token", obj.token);
		params = params.set("idAcquistoPermesso", obj.idAcquistoPermesso);

		if (obj.data) {
			params = params.set("data", obj.data);
		}

		if (obj.hooking) {
			params = params.set("hooking", "true");
		}

		this.options.params = params;

		return this.http.get(environment.basePath + "/api/uscite-annotazioni-permesso", this.options);
	}

	aggiungiAnnotazione(obj: {
		token: string;
		idAcquistoPermesso: number;
		testo: string;
	}): Observable<any> {

		delete this.options.params;

		const o = {
			token: obj.token,
			idAcquistoPermesso: obj.idAcquistoPermesso,
			testo: obj.testo
		};

		return this.http.post(environment.basePath + "/api/annotazione", o, this.options);
	}

	modificaAnnotazione(obj: {
		token: string;
		idAcquistoPermesso: number;
		idAnnotazione: number;
		testo: string;
	}): Observable<any> {

		delete this.options.params;

		const o = {
			token: obj.token,
			idAcquistoPermesso: obj.idAcquistoPermesso,
			idAnnotazione: obj.idAnnotazione,
			testo: obj.testo
		};

		return this.http.put(environment.basePath + "/api/annotazione", o, this.options);
	}

	eliminaAnnotazione(obj: {
		token: string;
		idAcquistoPermesso: number;
		idAnnotazione: number;
	}): Observable<any> {

		delete this.options.params;

		let params = new HttpParams();
		params = params.set("token", obj.token);
		params = params.set("idAcquistoPermesso", obj.idAcquistoPermesso);
		params = params.set("idAnnotazione", obj.idAnnotazione);

		this.options.params = params;

		return this.http.delete(environment.basePath + "/api/annotazione", this.options);
	}

	getAcquistiPermesso(obj: {
		token: string;
		idPermesso: number;
	}): Observable<any> {

		delete this.options.params;

		let params = new HttpParams();
		params = params.set("token", obj.token);
		params = params.set("idPermesso", obj.idPermesso);

		this.options.params = params;

		return this.http.get(environment.basePath + "/api/acquisti-permesso", this.options);
	}

	getMapInfo(obj: {
		idPermesso: number;
	}): Observable<any> {

		delete this.options.params;

		let params = new HttpParams();
		params = params.set("idPermesso", obj.idPermesso);

		this.options.params = params;

		return this.http.get(environment.basePath + "/api/map-info", this.options);
	}

	getPrezzoScontato(obj: {
		token: string;
		idPermesso: number;
		codiceSconto: string;
		emailAltroUtente?: string;
	}): Observable<any> {

		delete this.options.params;

		let params = new HttpParams();
		params = params.set("token", obj.token);
		params = params.set("idPermesso", obj.idPermesso);
		params = params.set("codiceSconto", obj.codiceSconto);

		if (obj.emailAltroUtente) {
			params = params.set("emailAltroUtente", obj.emailAltroUtente);
		}

		this.options.params = params;

		return this.http.get(environment.basePath + "/api/prezzo-scontato", this.options);
	}

	logAppError(obj: {
		message: string;
	}): Observable<any> {

		delete this.options.params;

		const o = {
			message: obj.message
		};

		return this.http.post(environment.basePath + "/api/log-app-error", o, this.options);
	}
}
