import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  public token: string;
  public tipoUtente: string;
  public nome: string;
  public cognome: string;
  public maggiorenne: boolean;

  constructor() {
    
  }

  public clear() {
    this.token = null;
    this.tipoUtente = null;
    this.nome = null;
    this.cognome = null;
    this.maggiorenne = null;
  }
}
