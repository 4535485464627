import { ErrorHandler, Injectable } from "@angular/core";
import { BackendService } from "../services/backend.service";

@Injectable()
export class MyErrorHandler implements ErrorHandler {

    constructor(
        private backendService: BackendService
    ) {

    }
    
    handleError(error: any): void {

        console.error(error);
        
        this.backendService.logAppError({
            message: error.message + "\n\n" + error.stack
          }).subscribe({
            next: () => {},
            error: () => {}
          });
    }
}
