import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

	public readonly TIPOLOGIA_PERMESSO = {
		RISERVE_NUMERO_CHIUSO: 1,
		ALTRE_RISERVE: 2,
		PERMESSO_OSPITE: 3,
		PERMESSO_LAGHI: 4,
		PESCA_OTTOBRE: 5
	};

  public readonly TECNICA_PESCA = {
		MOSCA: 1,
		SPINNING: 2,
		ESCA_NATURALE: 3,
		CARPFISHING: 4,
		KILL: 5,
		NO_KILL: 6,
		ANGUILLA: 7
	};

	public readonly SUPPORTED_LANGUAGES = [{
		code: "en",
		description: "English"
	},
	{
		code: "it",
		description: "Italiano"
	}];

	public readonly SYNC_INTERVAL = 1500;

	public readonly PATH_PRIVACY_POLICY = "/documenti/Privacy_policy.pdf";

	public readonly PATH_TERMINI_CONDIZIONI = "/documenti/Termini_e_condizioni_generali_di_utilizzo.pdf";

  constructor() {

  }

  getDataOdierna(): string {

    const now = new Date();

    let day = now.getDate().toString();

    if (day.length == 1) {
      day = "0" + day;
    }

    let month = (now.getMonth() + 1).toString();

    if (month.length == 1) {
      month = "0" + month;
    }

    let year = now.getFullYear().toString();

    return year + "-" + month + "-" + day;
  }

  getOraCorrente(): string {

		const now = new Date();

		let ore = now.getHours().toString();

		if (ore.length == 1) {
			ore = "0" + ore;
		}

		let minuti = now.getMinutes().toString();

		if (minuti.length == 1) {
			minuti = "0" + minuti;
		}

		let secondi = now.getSeconds().toString();

		if (secondi.length == 1) {
			secondi = "0" + secondi;
		}

		const sOra = ore + ":" + minuti + ":" + secondi;

		return sOra;
	}

  formatDate(data: string, lingua: string): string {
    return formatDate(data, "longDate", lingua);
  }

  getNumeroRandom() {
		return Math.round(Math.random() * 1000000);
	}
}
