<ion-app>
  <ion-split-pane contentId="main-content" [disabled]="true">
    <ion-menu contentId="main-content" type="overlay">
      <ion-content>
        <img src="assets/images/logo.png" style="display: block;margin:10px auto;max-width: 200px;height:auto;">
        <ion-list>
          <ion-menu-toggle auto-hide="false">
            <ion-item routerDirection="root" [routerLink]="'start'" lines="none"
              detail="false" routerLinkActive="selected">
              <ion-icon slot="start" name="home"></ion-icon>
              <ion-label>{{ 'HOMEPAGE' | translate }}</ion-label>
            </ion-item>
            <ion-item button *ngIf="userDataService.token && userDataService.tipoUtente == 'utente'" routerDirection="forward" [routerLink]="'miei-permessi'" lines="none"
              detail="false" routerLinkActive="selected">
              <ion-icon slot="start" name="documents"></ion-icon>
              <ion-label>{{ 'I_MIEI_PERMESSI' | translate }}</ion-label>
            </ion-item>
            <ion-item button *ngIf="userDataService.token && userDataService.tipoUtente == 'utente'" routerDirection="forward" [routerLink]="'archivio-permessi'" lines="none"
              detail="false" routerLinkActive="selected">
              <ion-icon slot="start" name="file-tray-full"></ion-icon>
              <ion-label>{{ 'ARCHIVIO_PERMESSI' | translate }}</ion-label>
            </ion-item>
            <ion-item button *ngIf="!userDataService.token || (userDataService.token && userDataService.tipoUtente == 'utente')" routerDirection="forward" [routerLink]="'guide'" lines="none"
              detail="false" routerLinkActive="selected">
              <ion-icon slot="start" name="people"></ion-icon>
              <ion-label>{{ 'GUIDE' | translate }}</ion-label>
            </ion-item>
            <ion-item button *ngIf="userDataService.token" routerDirection="forward" [routerLink]="'modifica-dati'" lines="none"
              detail="false" routerLinkActive="selected">
              <ion-icon slot="start" name="pencil"></ion-icon>
              <ion-label>{{ 'MODIFICA_DATI' | translate }}</ion-label>
            </ion-item>
            <ion-item *ngIf="!userDataService.token" routerDirection="root" [routerLink]="'login'" lines="none"
              detail="false" routerLinkActive="selected">
              <ion-icon slot="start" name="log-in"></ion-icon>
              <ion-label>{{ 'LOGIN' | translate }}</ion-label>
            </ion-item>
            <ion-item button *ngIf="userDataService.token" lines="none" (click)="onLogoutClick()"
              detail="false">
              <ion-icon slot="start" name="log-out"></ion-icon>
              <ion-label>{{ 'LOGOUT' | translate }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
